import React from "react";
import "./testimonials.css";
import AVTR1 from "../../assets/avatar1.jpg";
import AVTR2 from "../../assets/avatar2.jpg";
import AVTR3 from "../../assets/avatar3.jpg";
import AVTR4 from "../../assets/avatar4.png";

// import Swiper core and required modules
import { Pagination } from "swiper";

import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
const data = [
  {
    avatar: AVTR1,
    name: "Micha Stocks",
    review: "Tesla To the Moon",
  },
  {
    avatar: AVTR2,
    name: "Elon Musk",
    review:
      "“Don’t confuse schooling with education. I didn’t go to Harvard but the people that work for me did.”",
  },
  {
    avatar: AVTR3,
    name: "Stan Lee",
    review: `"if you have an idea that you genuinely think is good don't let some idiot talk you out of it"`,
  },
  {
    avatar: AVTR4,
    name: "Albert Einstein",
    review: `"We cannot solve our problems with the same thinking we used when we created them"`,
  },
];

function Testimonials() {
  return (
    <section id="testimonials">
      <h5>Review from clients</h5>
      <h2>Testimonials</h2>
      <Swiper
        className="container testimonials__container"
        // install Swiper modules
        modules={[Pagination]}
        spaceBetween={40}
        slidesPerView={1}
        pagination={{ clickable: true }}
      >
        {data.map(({ avatar, name, review }, index) => {
          return (
            <SwiperSlide key={index} className="testimonial">
              <div className="client__avatar">
                <img src={avatar} />
              </div>
              <h5 className="client__name">{name}</h5>
              <small className="client__review">{review}</small>
            </SwiperSlide>
          );
        })}
      </Swiper>
    </section>
  );
}

export default Testimonials;
